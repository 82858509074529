export default {
    // texto
    brand: process.env.VUE_APP_BRAND_NAME,
    /**
     *
     * @param {string} pre - Verifica se há o uso da preposição "a"
     * @returns
     */
    brandWithArtigo: (pre = '') => {
        const [brand, artigo] = [process.env.VUE_APP_BRAND_NAME, process.env.VUE_APP_ARTIGO]
        if (pre == artigo) return pre.concat(` ${brand}`)
        return `${pre}${artigo}`.concat(` ${brand}`)
    },
    subscriptionPrefix: process.env.VUE_APP_SUBSCRIPTION_PREFIX,
    savingsDashboardTitle: process.env.VUE_APP_SAVINGS_DASHBOARD_TITLE || 'Poupança',

    // hide screens
    hideScreenEducational: process.env.VUE_APP_HIDE_SCREEN_EDUCATIONAL === 'true',
    hideScreenSpecialists: process.env.VUE_APP_HIDE_SCREEN_SPECIALISTS === 'true',
    hideScreenTasks: process.env.VUE_APP_HIDE_SCREEN_TASKS === 'true',

    // colors
    expensesColor: process.env.VUE_APP_EXPENSES,

    // imagens
    logo: require(process.env.VUE_APP_LOGO),
    logoSmartphone: require(process.env.VUE_APP_LOGO_SMARTPHONE),
    brandIcon: require(process.env.VUE_APP_BRAND),
    brandIcon2: require('../assets/img/brand_2.png'),
    background: require(process.env.VUE_APP_BACKGROUND_BG),
    chatIcon: require(process.env.VUE_APP_CHAT_ICON),
    listIcon: require(process.env.VUE_APP_LIST_ICON),
    placeholder: require(process.env.VUE_APP_PLACEHOLDER_IMG),

    // config
    socialLoginEnabled: process.env.VUE_APP_SOCIAL_LOGIN_ENABLED === 'true',
    facebookLoginEnabled: process.env.VUE_APP_FACEBOOK_LOGIN_ENABLED === 'true',
    googleLoginEnabled: process.env.VUE_APP_GOOGLE_LOGIN_ENABLED === 'true',
    appleLoginEnabled: process.env.VUE_APP_APPLE_LOGIN_ENABLED === 'true',
    version: process.env.VUE_APP_VERSION,
    host: process.env.VUE_APP_HOST_URL,

    // links
    usageTerms: process.env.VUE_APP_USAGE_TERMS,
    privatePolicy: process.env.VUE_APP_PRIVATE_POLICY,
    contactPhone: process.env.VUE_APP_CONTACT_PHONE,
}
