<template>
    <div class="download-and-upload-buttons">
        <file-upload-button accept=".xml,.xlsx" @change="(sheet) => $emit('read-sheet', sheet)" />
        <label title="Baixar modelo" @click="downloadTemplate">
            <v-icon size="24" color="white">mdi-download</v-icon>
        </label>
    </div>
</template>

<script setup>
import FileUploadButton from '../../../../inputs/FileUploadButton.vue'

import planilha from '../../../../../assets/documents/templates/template-planejamento.xlsx'

import util from '../../../../../common/util'

defineEmits(['read-sheet'])

const downloadTemplate = () => {
    util.downloadFile(planilha, 'modelo-de-planilha-do-planejamento.xlsx')
}
</script>

<style lang="scss" scoped>
.download-and-upload-buttons {
    @include flex-between();
    align-items: center;
    width: 75px;
    position: absolute;
    right: 32px;

    label {
        background-color: var(--action-btn-color);
        border-radius: 50%;
        padding: 8px;
        text-align: center;
        vertical-align: middle;
        font-size: 16px;
        color: white;
        cursor: pointer;
        height: 32px;

        &:hover {
            opacity: 0.9;
        }
    }
}
</style>
