<template>
    <div title="Subir planilha" class="file-upload-button">
        <input v-bind="$attrs" type="file" id="file-button" @change="onLoadFile" />

        <label for="file-button">
            <v-icon size="24" color="white">mdi-upload</v-icon>
        </label>
    </div>
</template>

<script setup>
const emit = defineEmits(['change'])

const onLoadFile = (event) => {
    if (event.target.files.length) {
        emit('change', event.target.files[0])
        document.getElementById('file-button').value = null
    }
}
</script>

<style lang="scss" scoped>
.file-upload-button {
    input {
        display: none;
    }

    label {
        background-color: var(--action-btn-color);
        border-radius: 50%;
        padding: 8px;
        text-align: center;
        vertical-align: middle;
        font-size: 16px;
        color: white;
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }
}
</style>
