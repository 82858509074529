import moment from 'moment'

import { mainStore } from '../../../../../store'

import util, { noTimezone } from '../../../../../common/util'

const dictionary = {
    revenues: {
        Salário: 'Salário',
        Adiantamento: 'Adiantamento ou Vale',
        'Pró Labore': 'Pró-labore ou Receita via PJ',
        Informal: 'Outras Rendas',
        Comissões: 'Comissão',
        Benefícios: 'Benefício (Outros)',
        'Aluguel (Receita)': 'Aluguel',
        Pensão: 'Pensão',
        Bônus: 'Bônus',
        'Não Mesal': 'Renda Extra',
        Outras: 'Outras Rendas',
    },
    fixed: {
        Condomínio: 'Condomínio',
        Luz: 'Luz',
        Aluguel: 'Aluguel',
        Água: 'Água',
        Gás: 'Gás',
        Combo: 'Combo (TV, Internet, Telefone)',
        Internet: 'Internet',
        TV: 'TV',
        'Telefone Fixo': 'Telefone Fixo',
        Celular: 'Celular',
        'Funcionário (diarista, doméstica, babá, etc.)': 'Funcionário Doméstico (Outros)',
        'Profissional (psicólogo, contador, professor particular, etc.)': 'Serviços (Outros)',
        'Educação (escola, faculdade, cursos, etc.)': 'Educação (Fixa)',
        'Atividade Física (academia, lutas, futebol, etc.)': 'Atividades Físicas (Outros)',
        'Atividade Extra (música, bordado, teatro, etc.)': 'Atividades Extras (Outros)',
        'Saúde (convênio médico, odontológico, medicamentos fixos, etc.)': 'Saúde (Fixa)',
        'Taxas (tarifas bancárias, taxas de manutenção, etc.)': 'Taxas bancárias (Outros)',
        'Pet (creche, alimentação, banho, tosa, etc.)': 'Pet (banho, tosa, alimentação)',
        'Impostos (IPTU, IPVA, IR, etc.)': 'Impostos (Outros)',
        'Doações (dízimos, ofertas, outras doações, etc.)': 'Doações',
        'Assinaturas (serviços de streaming de vídeo ou músicas, vinhos, etc.)': 'Assinaturas',
        Outros: 'Diversos (Fixa)',
    },
    variable: {
        'Alimentação (mercado, açougue, padaria, feira, etc.)': 'Alimentação (Outros)',
        'Transporte (combustível, estacionamento, aplicativos de transporte, transporte público, etc.)':
            'Transporte (Outros)',
        'Lazer (restaurantes e bares, pedido no delivery, cinema e teatro, etc.)': 'Lazer (Outros)',
        'Saúde e Estética (farmácia, salão, barbearia, manicure, etc.)': 'Saúde (Variável)',
        'Compras e Presentes (vestuário, itens para casa, acessórios, etc.)': 'Compras (Outros)',
        Pet: 'Pet (Outros)',
        Outros: 'Diversos (Variável)',
    },
    debts: {
        'Empréstimo Pessoal': 'emprestimopessoal',
        Consignado: 'emprestimoconsignado',
        Terceiros: 'emprestimocomterceiros',
    },
    goods: {
        Imóvel: 'property',
        Automóvel: 'vehicle',
        Motocicleta: 'motorcycle',
    },
}

export default class ProcessExcelData {
    store = mainStore()

    revenuesAndExpenses
    creditCard
    debts
    goods

    payload = {
        transactionData: {
            revenues: [],
            fixed: [],
            variable: [],
            debts: [],
        },
        goods: [],
    }

    currentRow = 7
    reachEnd = false

    constructor(workbook) {
        this.revenuesAndExpenses = workbook.getWorksheet('Receitas e despesas')
        this.creditCard = workbook.getWorksheet('Cartão de crédito')
        this.debts = workbook.getWorksheet('Dívidas')
        this.goods = workbook.getWorksheet('Bens')
    }

    /**
     *
     * @param {string} threshrold
     * @param {string} worksheet
     * @returns {void}
     */
    searchFinalRow(threshrold, worksheet = 'revenuesAndExpenses') {
        while (!this.reachEnd) {
            let row = this[worksheet].getRow(this.currentRow)

            if (!row || row?.getCell(2)?.value === threshrold) this.reachEnd = true
            else if (!threshrold && !row?.getCell(2)?.value) this.reachEnd = true
            else this.currentRow++
        }
    }

    processRevenues() {
        let rows = []

        const revenuesCategories = this.store.filteredCategories('revenues', this.store.selectedUser)
        const defaultCategory = revenuesCategories.find((c) => c.name === 'Outras rendas')
        let startRow = this.currentRow

        this.searchFinalRow('Despesas Fixas')

        rows.push(...this.revenuesAndExpenses.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let [categoryName, description, value] = [row.getCell(2).value, row.getCell(3).value, row.getCell(4).value]

            if (value && typeof value === 'number') {
                const category = revenuesCategories.find((c) => c.name === dictionary.revenues[categoryName])
                this.payload.transactionData.revenues.push({
                    name: category?.name || defaultCategory.name,
                    category: category?._id || defaultCategory._id,
                    description,
                    value: value.toFixed(2),
                })
            }
        })
    }

    processFixed() {
        this.reachEnd = false

        let rows = []

        const fixedCategories = this.store.newGroupedCategories(true, true, this.store.selectedUser)[0].data
        const defaultCategory = fixedCategories.find((c) => c.name === 'Diversos (Fixa)')
        let startRow = this.currentRow

        this.searchFinalRow('Despesas Variáveis')

        rows.push(...this.revenuesAndExpenses.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let [categoryName, description, value] = [row.getCell(2).value, row.getCell(3).value, row.getCell(4).value]

            if (value && typeof value === 'number') {
                const category = fixedCategories.find((c) => c.name === dictionary.fixed[categoryName])
                this.payload.transactionData.fixed.push({
                    name: category?.name || defaultCategory.name,
                    category: category?._id || defaultCategory._id,
                    description,
                    value: value.toFixed(2),
                })
            }
        })
    }

    processVariable() {
        this.reachEnd = false

        let rows = []

        const variableCategories = this.store.newGroupedCategories(true, true, this.store.selectedUser)[1].data
        const defaultCategory = variableCategories.find((c) => c.name === 'Diversos (Variável)')
        let startRow = this.currentRow

        this.searchFinalRow('')

        rows.push(...this.revenuesAndExpenses.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let [categoryName, description, value] = [row.getCell(2).value, row.getCell(3).value, row.getCell(4).value]

            if (value && typeof value === 'number') {
                const category = variableCategories.find((c) => c.name === dictionary.variable[categoryName])
                this.payload.transactionData.variable.push({
                    name: category?.name || defaultCategory.name,
                    category: category?._id || defaultCategory._id,
                    description,
                    value: value.toFixed(2),
                })
            }
        })
    }

    processCreditCard() {
        this.reachEnd = false

        let rows = []

        const category = this.store.categories.find(
            (cat) => util.sanitize(cat.name) === 'cartaodecredito' && !cat.category,
        )
        let startRow = (this.currentRow = 8)
        let startDate = noTimezone(this.creditCard.getRow(8).getCell(7).value)

        this.searchFinalRow('', 'creditCard')

        rows.push(...this.creditCard.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let description = `${row.getCell(2).value} - ${row.getCell(3).value}`
            let closeOnDate = Number(row.getCell(4)?.value?.split(' ')?.[1] || 1)

            for (let i = 7; i <= 18; i++) {
                let value = row.getCell(i).value

                if (value && typeof value === 'number') {
                    this.payload.transactionData.debts.push({
                        name: category?.name,
                        category: category._id,
                        description,
                        value: value.toFixed(2),
                        date: moment(startDate)
                            .add(i - 7, 'M')
                            .date(closeOnDate)
                            .format('YYYY-MM-DD'),
                        recurrent: false,
                        recurrentType: undefined,
                    })
                }
            }
        })
    }

    processDebts() {
        this.reachEnd = false

        let rows = []

        const debtsCategories = this.store.newGroupedCategories(true, true, this.store.selectedUser)[2].data
        const defaultCategory = debtsCategories.find((c) => util.sanitize(c.name) === 'outrasdividaseparcelas')
        let startRow = (this.currentRow = 8)

        this.searchFinalRow('', 'debts')

        rows.push(...this.debts.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let [categoryName, description, value, lastInstallment] = [
                row.getCell(2).value,
                row.getCell(3).value,
                row.getCell(6).value,
                row.getCell(7).value,
            ]

            if (value && typeof value === 'number') {
                const category = debtsCategories.find((c) => util.sanitize(c.name) === dictionary.debts[categoryName])
                this.payload.transactionData.debts.push({
                    name: category?.name || defaultCategory.name,
                    category: category?._id || defaultCategory._id,
                    description,
                    value: value.toFixed(2),
                    lastInstallment: Number(lastInstallment),
                    recurrentType: undefined,
                })
            }
        })
    }

    processGoods() {
        this.reachEnd = false

        let rows = []
        let startRow = (this.currentRow = 8)

        this.searchFinalRow('', 'goods')

        rows.push(...this.goods.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let [categoryName, marketValue, financed, settlementBalance, installment, lastInstallment] = [
                row.getCell(2).value,
                row.getCell(3).value,
                row.getCell(4).value,
                row.getCell(6).value,
                row.getCell(7).value,
                row.getCell(8).value,
            ]

            if (marketValue && typeof marketValue === 'number') {
                this.payload.goods.push({
                    type: dictionary.goods[categoryName] || 'other',
                    marketValue: Number(marketValue.toFixed(2)),
                    lastInstallment: Number(lastInstallment),
                    financed: !!financed?.result,
                    settlementBalance: Number(settlementBalance.toFixed(2)),
                    installment: Number(installment.toFixed(2)),
                    date: noTimezone(new Date()).toISOString(),
                    user: this.store.selectedUser,
                })
            }
        })
    }

    process() {
        this.processRevenues()
        this.processFixed()
        this.processVariable()
        this.processCreditCard()
        this.processDebts()
        this.processGoods()

        return this.payload
    }
}
